<template>
    <form-modal
        ref="formModal"
        :title="i18nPosCheck"
        size="xl">
        <template v-slot:form>
            <b-overlay :show="loadingChecks" rounded="sm">
                <b-row>
                    <b-col md="6">
                        <gmap-map
                            ref="gmap"
                            :center="center"
                            :zoom="mapZoom"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: true,
                                disableDefaultUi: false,
                                maxZoom: 17,
                            }"
                            style="width: 100%; height: 80vh"
                        >
                            <GmapMarker
                                v-if="currentCheck && validLatLong(currentCheck.location_in)"
                                :position="latLngFromArray(currentCheck.location_in)"
                                :icon="checkInMarker"
                            />
                            <GmapMarker
                                v-if="currentCheck && validLatLong(currentCheck.location_out)"
                                :position="latLngFromArray(currentCheck.location_out)"
                                :icon="checkOutMarker"
                            />
                            <GmapMarker
                                v-if="currentCheck && currentCheck.pointOfSale && validLatLong(currentCheck.pointOfSale.location)"
                                :position="latLngFromArray(currentCheck.pointOfSale.location)"
                                :icon="pdvMarker"
                            />
                        </gmap-map>
                    </b-col>
                    <b-col md="6">
                        <b-row class="m-auto imagePreDiv">
                            <label class="label-pdv">
                                {{ getI18n(i18nCommon, 'image') }}
                            </label>
                            <div class="imageDiv d-flex justify-content-center">
                                <a
                                    v-if="currentCheck && currentCheck.file && currentCheck.file.url"
                                    :href="currentCheck.file.url"
                                    target="_blank"
                                >
                                    <img
                                        :src="currentCheck.file.url"
                                        fluid
                                        class="m-auto"
                                        alt="Cover Image"
                                    />
                                </a>
                                <img
                                    v-else
                                    src="@assets/images/no-image.png"
                                    fluid
                                    class="m-auto"
                                    alt="No Image Upload"
                                />
                            </div>
                        </b-row>
                        <b-row class="pt-4">
                            <b-col>
                                <b-form-group
                                    :label="getI18n('USERS', 'TITLES.user')"
                                    class="label-pdv"
                                    label-for="user"
                                >
                                    <b-form-input
                                        id="user"
                                        v-model="currentUserName"
                                        class="input-pdv-blue"
                                        disabled
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'FIELDS.forced_checkout')"
                                    class="label-pdv"
                                    label-for="forced_checkout"
                                >
                                    <b-form-input
                                        id="forced_checkout"
                                        v-model="currentForcedCheckout"
                                        class="input-pdv-blue"
                                        disabled
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group
                                    :label="getI18n(i18nCommon, 'pos')"
                                    class="label-pdv"
                                    label-for="pos"
                                >
                                    <b-form-input
                                        id="pos"
                                        v-model="currentPosName"
                                        class="input-pdv-blue"
                                        disabled
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n(i18nCommon, 'datetime_in')"
                                    class="label-pdv"
                                    label-for="datetime_in"
                                >
                                    <b-form-input
                                        id="datetime_in"
                                        v-model="formattedDatetimeIn"
                                        class="input-pdv-blue"
                                        disabled
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group
                                    :label="getI18n(i18nCommon, 'location_in')"
                                    class="label-pdv"
                                    label-for="location_in"
                                >
                                    <b-form-input
                                        id="location_in"
                                        v-model="formattedLocationIn"
                                        class="input-pdv-blue"
                                        disabled
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'FIELDS.battery_in')"
                                    class="label-pdv"
                                    label-for="battery_in"
                                >
                                    <b-form-input
                                        id="battery_in"
                                        v-model="formattedBatteryIn"
                                        class="input-pdv-blue"
                                        disabled
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n(i18nCommon, 'datetime_out')"
                                    class="label-pdv"
                                    label-for="datetime_out"
                                >
                                    <b-form-input
                                        id="datetime_out"
                                        v-model="formattedDatetimeOut"
                                        class="input-pdv-blue"
                                        disabled
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group
                                    :label="getI18n(i18nCommon, 'location_out')"
                                    class="label-pdv"
                                    label-for="location_out"
                                >
                                    <b-form-input
                                        id="location_out"
                                        v-model="formattedLocationOut"
                                        class="input-pdv-blue"
                                        disabled
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'FIELDS.battery_out')"
                                    class="label-pdv"
                                    label-for="battery_out"
                                >
                                    <b-form-input
                                        id="battery_out"
                                        v-model="formattedBatteryOut"
                                        class="input-pdv-blue"
                                        disabled
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-overlay>
        </template>
        <template v-slot:footer>
            <div class="d-flex justify-content-center">
                <save-button
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.previous')"
                    :disabled="loadingChecks || !notAtHeadOfList"
                    @onClick="move(-1)"
                />
                <save-button
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    :disabled="loadingChecks || !notAtEndOfList"
                    @onClick="move(1)"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import FormModal from '@/src/components/FormModal.vue'
import { Map, Marker, gmapApi } from 'gmap-vue'
import { faSignInAlt, faSignOutAlt, faMapMarkerAlt, } from '@fortawesome/free-solid-svg-icons'
import SaveButton from '@/src/components/SaveButton'
import filterVuetable from '@src/mixins/filterVuetable'
import vuetableFormatter from '@src/mixins/vuetableFormatters'

import PosCheckService from '@src/services/PosCheckService'

const i18nCommon = 'COMMON'
const i18nKey = 'POS_CHECK'

export default {
    components: {
        FormModal,
        SaveButton,
        'gmap-map': Map,
        GmapMarker: Marker,
    },
    mixins: [
        filterVuetable,
        vuetableFormatter
    ],
    data() {
        return {
            i18nCommon,
            i18nKey,
            currentOffset: 0,
            mapZoom: 14,
            loadingChecks: false,
            checks: [],
            center: {
                lat: -15.793889,
                lng: -47.882778,
            },
            checkInMarker: {
                path: faSignInAlt.icon[4],
                scale: 0.05,
                fillColor: '#89E09C',
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
            },
            checkOutMarker: {
                path: faSignOutAlt.icon[4],
                scale: 0.05,
                fillColor: '#CE0404',
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
            },
            pdvMarker: {
                path: faMapMarkerAlt.icon[4],
                scale: 0.05,
                fillColor: '#2E7FEB',
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
            },
            filters: {},
        }
    },
    computed: {
        google: gmapApi,
        i18nPosCheck() {
            return this.getI18n(i18nKey, 'TITLES.poscheck')
        },
        i18nCheckin() {
            return this.getI18n(i18nKey, 'TITLES.checkin')
        },
        i18nCheckOut() {
            return this.getI18n(i18nKey, 'TITLES.checkout')
        },
        currentCheck() {
            return this.checks[this.currentOffset]
        },
        currentUserName() {
            return this.currentCheck && this.currentCheck.user.name
                ? this.currentCheck.user.name
                : ''
        },
        currentPosName() {
            return this.currentCheck && this.currentCheck.pointOfSale.name
                ? this.currentCheck.pointOfSale.name
                : ''
        },
        formattedDatetimeIn() {
            return this.currentCheck && this.currentCheck.datetime_in
                ? this.formatDateTime(this.currentCheck.datetime_in, false)
                : ''
        },
        formattedDatetimeOut() {
            return this.currentCheck && this.currentCheck.datetime_out
                ? this.formatDateTime(this.currentCheck.datetime_out, false)
                : ''
        },
        formattedLocationIn() {
            return this.currentCheck && this.currentCheck.location_in
                ? this.formatLatLong(this.currentCheck.location_in)
                : ''
        },
        formattedLocationOut() {
            return this.currentCheck && this.currentCheck.location_out
                ? this.formatLatLong(this.currentCheck.location_out)
                : ''
        },
        formattedBatteryIn() {
            return this.currentCheck && this.currentCheck.battery_in
                ? this.currentCheck.battery_in + '%'
                : ''
        },
        formattedBatteryOut() {
            return this.currentCheck && this.currentCheck.battery_out
                ? this.currentCheck.battery_out + '%'
                : ''
        },
        currentForcedCheckout() {
            return this.currentCheck && this.currentCheck.forced_checkout
                ? this.getI18n(i18nCommon, 'yes')
                : this.getI18n(i18nCommon, 'no')
        },
        notAtEndOfList() {
            return this.currentOffset < this.checks.length - 1
        },
        notAtHeadOfList() {
            return this.currentOffset !== 0
        },
    },
    watch: {
        'currentCheck.id': {
            handler() {
                this.markMapAndPanTo()
            },
        },
    },
    methods: {
        // modal
        async showModal(filters) {
            this.currentOffset = 0
            this.filters = filters
            this.$refs.formModal.show()
            await this.getPointOfSaleChecks()
            this.markMapAndPanTo()
        },

        move(direction) {
            this.currentOffset += direction
            this.markMapAndPanTo()
        },

        latLngFromArray(position) {
            return {
                lat: position[0],
                lng: position[1],
            }
        },

        async getPointOfSaleChecks() {
            this.loadingChecks = true
            const parameters = this.formatParameters({
                with: ['pointOfSale', 'user', 'file'],
                user_id: [this.filters.userId],
                datetime_in: {
                    from: this.filters.dateTimeIn.start,
                    to: this.filters.dateTimeIn.end,
                },
                point_of_sale_id: this.filters.pointOfSaleId ? [this.filters.pointOfSaleId] : [],
            })

            await PosCheckService.fetchAll(parameters)
                .then((response) => {
                    this.checks = response.data.data
                    this.loadingChecks = false
                    return response
                })
                .catch((err) => {
                    this.loadingChecks = false
                    return err
                })
        },
        allMarkers() {
            const markers = []
            if (this.currentCheck && this.validLatLong(this.currentCheck.location_in)) {
                markers.push(this.latLngFromArray(this.currentCheck.location_in))
            }
            if (this.currentCheck && this.validLatLong(this.currentCheck.location_out)) {
                markers.push(this.latLngFromArray(this.currentCheck.location_out))
            }
            if (this.currentCheck && this.currentCheck.pointOfSale && this.validLatLong(this.currentCheck.pointOfSale.location)) {
                markers.push(this.latLngFromArray(this.currentCheck.pointOfSale.location))
            }
            return markers
        },
        markMapAndPanTo() {
            this.$refs.gmap &&
                this.$refs.gmap.$mapPromise.then((map) => {
                    const markers = this.allMarkers()

                    const bounds = new this.google.maps.LatLngBounds()

                    markers.forEach((marker) => {
                        bounds.extend(
                            new this.google.maps.LatLng(marker.lat, marker.lng)
                        )
                    })
                    map.fitBounds(bounds)
                    map.setZoom(this.mapZoom)
                })
        },
        getPlaceholder(property) {
            return this.config[property] && this.config[property].original_name
                ? this.config[property].original_name
                : this.getI18n('COMMON', 'no_file_chosen')
        },

        replaceModalData(data) {
            Object.assign(this.modalConfig, data)
        },

        handleCancel() {
            this.$refs.formModal.hide()
        },

        formatLatLong(arrLatLong) {
            if (arrLatLong) {
                return '(' + arrLatLong[0] + ',' + arrLatLong[1] + ')'
            }
            return ''
        },

        validLatLong(arrLatLong) {
            if (arrLatLong && arrLatLong.length === 2) {
                if (arrLatLong[0] !== 0 && arrLatLong[1] !== 0) {
                    return true
                }
            }
            return false
        },
    },
}
</script>

<style lang="scss">
.imagePreDiv {
    width: 100%;
    height: 400px;
}
.imageDiv {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 400px;
    border: 1px solid #5899ef;
    box-sizing: border-box;
    border-radius: 12px;
}
.imageDiv > a > img {
    max-width: 100%;
    max-height: 100%;
}
.imageDiv > img {
    max-width: 100%;
    max-height: 100%;
}
</style>
