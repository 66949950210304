var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"col-12"},[_c('Breadcrumb',{attrs:{"items":_vm.items}}),_c('div',{staticClass:"row mt-3 mb-3"},[_c('div',{staticClass:"col-sm-8"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"required label-pdv",attrs:{"label":_vm.i18nDate,"label-for":"filter-date"}},[_c('date-picker',{attrs:{"value":_vm.filters.date,"max-date":new Date(),"disable-clean-button":true},on:{"update:value":function($event){return _vm.$set(_vm.filters, "date", $event)}}})],1)],1),_c('b-col',{attrs:{"md":"7"}},[_c('b-form-group',{staticClass:"label-pdv",attrs:{"label":_vm.i18nUser,"label-for":"filter-user"}},[_c('multiSelectWithService',{ref:"userMultiselect",attrs:{"id":'filter-users',"service":'users',"searchable":true,"multiple":true,"parameters":_vm.usersParameters},model:{value:(_vm.filters.users),callback:function ($$v) {_vm.$set(_vm.filters, "users", $$v)},expression:"filters.users"}})],1)],1)],1)],1),_c('div',{staticClass:"col-sm-4 align-self-center"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('filter-button',{staticClass:"col-md-6",on:{"onClick":_vm.filter}})],1)])])],1),_c('gmap-map',{ref:"gmap",staticStyle:{"width":"100%","height":"90vh"},attrs:{"center":_vm.center,"zoom":4,"options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            disableDefaultUi: false,
            maxZoom: 17,
        }}},_vm._l((_vm.latestCheckins),function(posCheck){return _c('GmapMarker',{key:posCheck.id + '_in',attrs:{"position":posCheck.position,"clickable":true,"icon":_vm.latestCheckinsMarker,"options":{ info: posCheck }},on:{"click":function($event){return _vm.toggleInfoWindow(posCheck)}}})}),1),_c('trackMapModal',{ref:"trackModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }