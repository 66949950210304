<script>
import Layout from '@layouts/main'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import { Map, Marker, gmapApi } from 'gmap-vue'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import LatestCheckinsService from '@src/services/dashboards/LatestCheckinsService'
import multiSelectWithService from '@src/components/multiSelectWithService'
import filterVuetable from '@src/mixins/filterVuetable'
import trackMapModal from '@src/router/views/dashboards/trackMap/trackMapModal'
import DatePicker from '@/src/components/DatePicker.vue'
import FilterButton from '@/src/components/FilterButton'

const i18nCommon = 'COMMON'
const i18nKey = 'DASHBOARDS'

export default {
    components: {
        Layout,
        Breadcrumb,
        'gmap-map': Map,
        GmapMarker: Marker,
        trackMapModal,
        DatePicker,
        multiSelectWithService,
        FilterButton,
    },
    mixins: [filterVuetable],

    data() {
        return {
            latestCheckins: [],
            center: {
                lat: -15.793889,
                lng: -47.882778,
            },
            latestCheckinsMarker: null,
            filters: {
                date: new Date(),
                users: [],
            },
            userId: 0,
            additionalParameters: {},
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    computed: {
        google: gmapApi,
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.dashboards',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18n(i18nCommon, 'geopositioning'),
                    active: true,
                },
            ]
        },
        i18nTrackMap() {
            return this.getI18n(i18nCommon, 'geopositioning')
        },
        i18nDate() {
            return this.getI18n(i18nCommon, 'date')
        },
        i18nUser() {
            return this.getI18nModified({
                prefix: 'USERS',
                suffix: 'TITLES.user',
                modifier: 2,
            })
        },
    },
    async mounted() {
        await this.getLatestCheckins()
        this.mapBounds()
    },
    methods: {
        createParameters() {
            return this.formatParameters({
                user_id: this.filters.users.map((user) => user.id),
                date: this.dateToValue(this.filters.date),
            })
        },
        mapBounds() {
            this.$refs.gmap.$mapPromise.then((map) => {
                this.latestCheckinsMarker = {
                    path: faMapMarkerAlt.icon[4],
                    scale: 0.07,
                    fillColor: "#2E7FEB",
                    fillOpacity: 1,
                    strokeWeight: 0,
                    rotation: 0,
                    anchor: new this.google.maps.Point(
                        this.center.lat,
                        this.center.lng
                    ),
                }

                if (this.latestCheckins.length > 0) {
                    const bounds = new this.google.maps.LatLngBounds()
                    for (const marker of this.latestCheckins) {
                        bounds.extend(
                            new this.google.maps.LatLng(
                                marker.position.lat,
                                marker.position.lng
                            )
                        )
                    }
                    map.fitBounds(bounds)
                }
            })
        },
        filter() {
            this.additionalParameters = this.createParameters()
            this.getLatestCheckins()
        },
        async getLatestCheckins() {
            const response = await LatestCheckinsService.fetchAll(
                this.additionalParameters
            )
                .then((response) => {
                    return response
                })
                .catch((err) => err)

            if (response.status.toString().charAt(0) === '2') {
                this.latestCheckins = response.data.data.map((posCheck) => {
                    return this.mapLocation(posCheck, 'location_in')
                })
                this.mapBounds()
            }
        },
        mapLocation(posCheck, field) {
            if (!posCheck[field]) {
                return null
            }
            posCheck.position = this.latLngFromArray(posCheck[field])
            return posCheck
        },
        latLngFromArray(position) {
            return {
                lat: position[0],
                lng: position[1],
            }
        },
        toggleInfoWindow(marker) {
            const filter = {
                userId: marker.user_id,
                dateTimeIn: {
                    start: this.dateToValue(this.filters.date),
                    end: this.dateToValue(this.filters.date),
                },
            }
            this.$nextTick(() => this.$refs.trackModal.showModal(filter))
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-8">
                    <b-row>
                        <b-col md="4">
                            <b-form-group
                                :label="i18nDate"
                                class="required label-pdv"
                                label-for="filter-date"
                            >
                                <date-picker
                                    :value.sync="filters.date"
                                    :max-date="new Date()"
                                    :disable-clean-button="true"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                            <b-form-group
                                :label="i18nUser"
                                class="label-pdv"
                                label-for="filter-user"
                            >
                                <multiSelectWithService
                                    :id="'filter-users'"
                                    ref="userMultiselect"
                                    v-model="filters.users"
                                    :service="'users'"
                                    :searchable="true"
                                    :multiple="true"
                                    :parameters="usersParameters"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-sm-4 align-self-center">
                    <div class="d-flex justify-content-end">
                        <filter-button class="col-md-6" @onClick="filter" />
                    </div>
                </div>
            </div>
        </div>
        <gmap-map
            ref="gmap"
            :center="center"
            :zoom="4"
            :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
                maxZoom: 17,
            }"
            style="width: 100%; height: 90vh"
        >
            <GmapMarker
                v-for="posCheck in latestCheckins"
                :key="posCheck.id + '_in'"
                :position="posCheck.position"
                :clickable="true"
                :icon="latestCheckinsMarker"
                :options="{ info: posCheck }"
                @click="toggleInfoWindow(posCheck)"
            />
        </gmap-map>
        <trackMapModal ref="trackModal" />
    </Layout>
</template>
